<template>
    <v-container v-if="isLoading">
        <slot name="loading"></slot>
    </v-container>
    <v-container v-else>
        <v-row class="ma-0">
            <v-col class="pa-0" cols="12" xl="9">
                <a-form ref="form" :submit="save" focus-on-error>
                    <v-card-title class="mb-4">
                        {{ isCreating ? 'Create Company' : 'Edit Company' }}
                        <v-spacer />
                        <v-btn
                            v-if="showTitleBackButton"
                            text
                            color="primary-color"
                            class="mb-n1"
                            title="Back to Companies"
                            @click="cancel"
                        >
                            <v-icon small :left="$vuetify.breakpoint.mdAndUp">
                                arrow-left
                            </v-icon>
                            {{
                                $vuetify.breakpoint.mdAndUp
                                    ? 'Back to Companies'
                                    : null
                            }}
                        </v-btn>
                    </v-card-title>

                    <v-card-text v-if="criticalError">
                        <v-icon large color="primary" class="mr-2">
                            circle-exclamation
                        </v-icon>
                        {{ criticalError }}
                    </v-card-text>

                    <v-card-text v-else>
                        <a-alert
                            v-if="isCreating"
                            type="info"
                            class="rounded mb-8 mt-n4"
                        >
                            Company address and contact info appear in Amp
                            content for viewers to identify or contact the
                            company. This will not be used for any form of
                            communication from AmpiFire. If you publish Amps for
                            multiple companies, please fill in the relevant
                            address and contact person for each, rather than
                            your own details.
                        </a-alert>
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col class="py-0">
                                        <h3 class="pb-4">Company Info</h3>
                                        <a-text-input
                                            v-model="company.name"
                                            label="Company Name"
                                            rules="required|max:255"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                        />
                                        <a-text-input
                                            v-model="company.address1"
                                            vid="address1"
                                            label="Company Address"
                                            rules="required|max:2048"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                        />
                                        <a-text-input
                                            v-model="company.address2"
                                            label="Address Line 2"
                                            rules="max:2048"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6" class="py-0">
                                        <a-text-input
                                            v-model="company.city"
                                            vid="city"
                                            label="City of Origin"
                                            rules="required|max:255"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6" class="py-0">
                                        <a-text-input
                                            v-model="company.postal_code"
                                            vid="postal_code"
                                            label="Postal Code"
                                            :rules="{
                                                required: isPostCodeRequired,
                                                max: 16
                                            }"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="6" class="py-0">
                                        <a-text-input
                                            v-model="company.state"
                                            vid="state"
                                            label="State / Province"
                                            rules="required|max:255"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6" class="py-0">
                                        <a-select-input
                                            v-model="company.country_id"
                                            vid="country_id"
                                            label="Country"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                            :items="countries"
                                            item-text="name"
                                            item-value="id"
                                            @input="countryChanged"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <div
                                            class="pa-4 mt-n1 mb-3 d-flex justify-space-between dirty-address-indicator"
                                        >
                                            <div class="d-flex align-center">
                                                <v-icon
                                                    v-if="isAddressDirty"
                                                    small
                                                    class="error--text mr-2"
                                                >
                                                    times-circle
                                                </v-icon>
                                                <v-icon
                                                    v-else
                                                    small
                                                    class="green--text mr-2"
                                                >
                                                    check-circle
                                                </v-icon>
                                                {{
                                                    isAddressDirty
                                                        ? 'Address Not Verified'
                                                        : 'Address Verified'
                                                }}
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row v-if="$vuetify.breakpoint.smAndDown">
                                    <v-col>
                                        <address-on-map
                                            :address="fullAddressComponents"
                                            class="mb-4"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col class="py-0">
                                        <h3 class="pb-4">Contact Info</h3>
                                        <a-text-input
                                            v-model="company.contact_name"
                                            vid="contact_name"
                                            label="Contact Name"
                                            rules="required|max:255"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                        />
                                        <a-text-input
                                            v-model="company.email"
                                            vid="email"
                                            label="Contact Email"
                                            rules="required|email|max:255"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                        />
                                        <a-phone-input
                                            ref="phone"
                                            v-model="company.phone"
                                            rules="required"
                                            label="Phone Number"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                        />
                                        <a-text-input
                                            v-model="company.website"
                                            vid="website"
                                            label="Website"
                                            rules="required|url|max:255"
                                            :disabled="!isEditable"
                                            autocomplete="off"
                                            :loading="isSaving"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        Many sites publish contact information.
                                        Use the toggles to share the information
                                        you want.
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <a-toggle
                                            v-model="company.display_email"
                                            name="display_email"
                                            label="Display email"
                                            :disabled="!isEditable"
                                            class="mt-0"
                                            hide-details
                                            :loading="isSaving"
                                        />
                                        <a-toggle
                                            v-model="company.display_phone"
                                            name="display_phone"
                                            label="Display phone number"
                                            :disabled="!isEditable"
                                            class="mb-4"
                                            hide-details
                                            :loading="isSaving"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row v-if="$vuetify.breakpoint.mdAndUp">
                            <v-col>
                                <address-on-map
                                    :address="fullAddressComponents"
                                    class="mb-4"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col class="py-0">
                                        <h3 class="pb-4">Additional Info</h3>
                                        <a-text-input
                                            v-model="company.year_founded"
                                            label="Year Founded"
                                            type="number"
                                            min="1700"
                                            :max="currentYear"
                                            :rules="`between:1700,${currentYear}`"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                        />
                                        <a-text-input
                                            v-model="company.business_niche"
                                            label="What Niche Does This Company Target?"
                                            rules="max:255"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                        />
                                        <a-text-input
                                            v-model="company.speciality"
                                            label="What Makes This Company Special?"
                                            textarea
                                            rows="1"
                                            rules="max:255"
                                            :disabled="!isEditable"
                                            :loading="isSaving"
                                        />
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-row>
                                    <v-col class="py-0">
                                        <h3 class="pb-4">Social Media</h3>
                                        <a-text-input
                                            v-model="company.twitter_handle"
                                            vid="twitter_handle"
                                            label="X Handle"
                                            :rules="{
                                                regex: twitterRegex,
                                                max: 16
                                            }"
                                            :disabled="!isEditable"
                                            html-hint
                                            hint="Only enter X handle. For example: <strong>@x_handle</strong>"
                                            prepend-icon="fab fa-x-twitter"
                                            :loading="isSaving"
                                        />
                                        <a-text-input
                                            v-model="company.facebook_page"
                                            vid="facebook_page"
                                            label="Facebook Page"
                                            :rules="{
                                                regex: facebookRegex,
                                                max: 255
                                            }"
                                            :disabled="!isEditable"
                                            hint="Only enter page name without facebook domain"
                                            prepend-icon="fab fa-facebook-f"
                                            :loading="isSaving"
                                        />
                                        <a-text-input
                                            v-model="company.instagram"
                                            label="Instagram Page"
                                            :rules="{
                                                regex: instaRegex,
                                                max: 255
                                            }"
                                            :disabled="!isEditable"
                                            hint="Only enter page name without instagram domain"
                                            prepend-icon="fab fa-instagram"
                                            :loading="isSaving"
                                        />
                                        <v-checkbox
                                            v-if="isReseller"
                                            v-model="company.gather_leads"
                                            name="gather_leads"
                                            :disabled="!isEditable"
                                        >
                                            <template #label>
                                                Gather Leads
                                                <v-tooltip bottom>
                                                    <template
                                                        #activator="{
                                                            on,
                                                            attrs
                                                        }"
                                                    >
                                                        <v-icon
                                                            v-bind="attrs"
                                                            small
                                                            right
                                                            v-on="on"
                                                        >
                                                            info-circle
                                                        </v-icon>
                                                    </template>
                                                    <span>
                                                        Requests customer
                                                        contact information via
                                                        an overlay form on your
                                                        company profile page.
                                                    </span>
                                                </v-tooltip>
                                            </template>
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="pb-4">
                                <h3 class="pb-4">Company Logo</h3>
                                <company-logo
                                    :disabled="!canUpdate"
                                    :company="company"
                                    @queue="queueJob"
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col class="pb-4">
                                <h3 class="pb-4">
                                    Business Registration Certificate
                                </h3>
                                <business-certificate
                                    :disabled="!canUpdate"
                                    :company="company"
                                    @queue="queueJob"
                                />
                            </v-col>
                        </v-row>

                        <v-row v-if="canSeeRssFeeds">
                            <v-col>
                                <h3 class="inline">RSS Feeds</h3>
                                <a-toggle
                                    v-model="company.enable_feeds"
                                    name="enable_feeds"
                                    label="Enable Feeds"
                                    :disabled="!isEditable"
                                    class="inline"
                                    hide-details
                                    :loading="isSaving"
                                />
                            </v-col>
                        </v-row>
                        <v-row v-if="canSeeRssFeeds && company.enable_feeds">
                            <v-col>
                                <company-rss-feeds :company="company" />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions
                        v-if="canUpdate"
                        class="mx-2 pb-4 pt-4 justify-space-between"
                        :class="{
                            'flex-column': $vuetify.breakpoint.smAndDown
                        }"
                    >
                        <v-btn
                            type="submit"
                            color="primary"
                            :loading="isSaving"
                            :block="$vuetify.breakpoint.smAndDown"
                        >
                            Save
                        </v-btn>
                        <v-btn
                            text
                            :block="$vuetify.breakpoint.smAndDown"
                            :disabled="isSaving"
                            @click="cancel"
                        >
                            Cancel
                        </v-btn>
                    </v-card-actions>
                </a-form>
            </v-col>
        </v-row>

        <non-verified-address
            id="cannot-verify-address"
            :address="actualFullAddress"
            @confirm="confirmNonVerified"
        />

        <suggested-address
            id="suggested-address"
            :address="address"
            @confirm="confirmSuggested"
        />
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';

import SectionLoading from '@/pages/Profile/sections/SectionLoading.vue';

import NonVerifiedAddress from './NonVerifiedAddress.vue';
import SuggestedAddress from './SuggestedAddress.vue';
import AddressOnMap from './AddressOnMap.vue';
import CompanyRssFeeds from './CompanyRssFeeds.vue';

import type { FullAddress } from '@/modules/GoogleMaps';
import type { AddressVerificationResult } from './';

import { AForm } from '@/components/AForm';
import { AAlert } from '@/components/AAlert';

import { ATextInput } from '@/components/AForm/Inputs/ATextInput';
import { APhoneInput } from '@/components/AForm/Inputs/APhoneInput';
import { AToggle } from '@/components/AForm/Inputs/AToggle';
import { ASelectInput } from '@/components/AForm/Inputs/ASelectInput';
import { AMediaUploader } from '@/components/AForm/Inputs/AMediaUploader';

import CompanyLogo from './CompanyLogo.vue';
import BusinessCertificate from './BusinessCertificate.vue';

import type { MediaResource } from '@/types/MediaResource';
import type { Country } from '@/types/Country';
import type { ServerResponse } from '@/types/ServerResponse';

const CompanyEditorProps = Vue.extend({
    name: 'CompanyEditor',
    props: {
        companyId: {
            type: Number,
            default() {
                return 0;
            }
        },
        userId: {
            type: Number,
            default() {
                return 0;
            }
        },
        isEditable: {
            type: Boolean,
            default() {
                return true;
            }
        },
        isCreating: {
            type: Boolean,
            default() {
                return false;
            }
        },
        showTitleBackButton: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

type QueueItem = () => Promise<void>;

@Component({
    components: {
        NonVerifiedAddress,
        SuggestedAddress,
        AddressOnMap,
        AForm,
        APhoneInput,
        ATextInput,
        AToggle,
        ASelectInput,
        AMediaUploader,
        SectionLoading,
        CompanyLogo,
        BusinessCertificate,
        AAlert,
        CompanyRssFeeds
    },
    computed: {
        ...mapGetters('user', ['isReseller', 'hasAiFeatures'])
    }
})
export default class CompanyEditor extends CompanyEditorProps {
    isReseller!: boolean;
    hasAiFeatures!: boolean;

    $refs!: {
        form: InstanceType<typeof AForm> & { errors: Record<string, string[]> };
        phone: InstanceType<typeof APhoneInput> & {
            choose: (c: string) => void;
        };
    };

    batch: QueueItem[] = [];

    company = {
        id: 0,
        user_id: 0,
        media_resources: [] as MediaResource[],

        name: '',
        address1: '',
        address2: '',
        city: '',
        postal_code: '',
        state: '',
        country_id: 226, // default to USA

        full_address: '',

        contact_name: '',
        email: '',
        phone: '',
        website: '',
        display_email: false,
        display_phone: false,

        year_founded: '',
        business_niche: '',
        speciality: '',

        twitter_handle: '',
        facebook_page: '',
        instagram: '',
        gather_leads: false,
        enable_feeds: false
    };

    propsToSave = new Set([
        'id',
        'user_id',

        'name',
        'address1',
        'address2',
        'city',
        'postal_code',
        'state',
        'country_id',

        'full_address',

        'contact_name',
        'email',
        'phone',
        'website',
        'display_email',
        'display_phone',

        'year_founded',
        'business_niche',
        'speciality',

        'twitter_handle',
        'facebook_page',
        'instagram',
        'gather_leads',
        'enable_feeds'
    ]);

    propsToOmitForNew = new Set(['id', 'user_id']);

    twitterRegex = /^@[a-zA-Z0-9_.]*$/;
    facebookRegex = /^[/#!a-zA-Z0-9(.>)_\-=?]+$/;
    instaRegex = /^[/#!a-zA-Z0-9(.>)_\-=?]+$/;

    countries = [] as Country[];

    countryISOs: {
        isoAndCountryIds: Record<string, number>;
        countryIdsAndIsos: Record<number, string>;
    } = { isoAndCountryIds: {}, countryIdsAndIsos: {} };

    isLoading = false;
    isSaving = false;

    address: AddressVerificationResult | null = null;

    pendingAddressVerification: null | (() => void) = null;

    criticalError = '';

    get canSeeRssFeeds() {
        return this.hasAiFeatures && this.isEditing && this.showRssFeeds;
    }

    get showRssFeeds() {
        return this.$route.query.showRss === '1';
    }

    get canUpdate() {
        return this.isEditable && !this.criticalError;
    }

    get currentYear() {
        return new Date().getFullYear();
    }

    get isAddressDirty() {
        // $refs are not reactive, so we have to bind to some reactive property
        const address = this.company.full_address;

        const shouldVerifyFullAddress = this.isCreating
            ? this.$refs.form?.isDirty
            : true;

        return shouldVerifyFullAddress
            ? address !== this.actualFullAddress
            : true;
    }

    get actualFullAddress() {
        return this.buildFullAddress();
    }

    get fullAddressComponents() {
        return this.prepareFullAddress();
    }

    get isEditing() {
        return Boolean(Number(this.companyId));
    }

    get canUploadMedia() {
        return this.canUpdate && !this.isCreating;
    }

    get isPostCodeRequired() {
        if (this.company.country_id) {
            const country = this.countries.find(
                ({ id }) => id === this.company.country_id
            );

            if (country) {
                return country.has_postal_code;
            }
        }

        return false;
    }

    get queryUserId() {
        return (
            (Array.isArray(this.$route.query.user_id)
                ? this.$route.query.user_id[0]
                : this.$route.query.user_id) || ''
        );
    }

    get companyUserId() {
        return (
            this.company.user_id ||
            this.userId ||
            this.queryUserId ||
            this.$store.state.user.id
        );
    }

    get observer() {
        return this.$refs.form.$refs.observer as unknown as InstanceType<
            typeof ValidationObserver
        >;
    }

    mounted() {
        this.setLoading();

        this.getData()
            .then(({ data }) => {
                if (data) {
                    if (data.company) {
                        // new company has no data
                        this.company = data.company;
                    }

                    this.countries = data.countries;

                    this.setAddressVerified();
                }
            })
            .finally(this.setLoading.bind(this, false));
    }

    setLoading(isLoading = true) {
        this.isLoading = isLoading;
    }

    setSaving(isSaving = true) {
        this.isSaving = isSaving;
    }

    setAddressVerified() {
        this.company.full_address = this.buildFullAddress();

        if (this.pendingAddressVerification) {
            this.pendingAddressVerification();
        }
    }

    buildFullAddress() {
        return [
            this.company.address1,
            this.company.city,
            [this.company.state, this.company.postal_code]
                .filter(Boolean)
                .join(' '),
            this.getCountryNameById(this.company.country_id)
        ]
            .filter(Boolean)
            .join(', ');
    }

    prepareFullAddress(): FullAddress {
        return {
            address: this.company.address1,
            city: this.company.city,
            state: this.company.state,
            postal_code: this.company.postal_code,
            country: this.getCountryNameById(this.company.country_id) || ''
        };
    }

    async save() {
        this.setSaving();

        if (this.isAddressDirty) {
            // MAGIC!
            await new Promise(resolve => {
                this.pendingAddressVerification = () => {
                    resolve(true);

                    this.pendingAddressVerification = null;
                };

                this.verifyAddress();
            });
        }

        return this.setData()
            .then(data => {
                if (!data?.meta?.success) {
                    this.$store.dispatch(
                        'notification/error',
                        'Unable to save Company. Please check the form for errors.'
                    );

                    this.setErrors(data.meta.errors);

                    return;
                }
                // media uploading needs that ID
                this.updateCompanyData(data?.data?.company);

                return this.processQueue()
                    .then(() => {
                        this.$store.dispatch(
                            'notification/success',
                            'Company saved'
                        );

                        this.onSave(data);
                    })
                    .catch(() => {
                        this.$store.dispatch(
                            'notification/error',
                            'Failed to process the batch'
                        );
                    });
            })
            .finally(this.setSaving.bind(this, false));
    }

    onSave(
        response: ServerResponse<{
            company: CompanyEditor['company'];
        }>
    ) {
        this.company = response.data.company;

        this.setAddressVerified();

        if (this.isCreating) {
            this.$emit('created', this.company.id);
        }

        this.$emit('saved', response);
    }

    async getData() {
        return (
            this.isCreating
                ? this.getNewCompanyData()
                : this.getExistingCompanyData()
        )
            .then(({ data }) => {
                if (data.meta && !data.meta.success) {
                    this.criticalError = data.meta.message;

                    return {};
                }

                return data;
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    async getNewCompanyData() {
        return this.$http.get('/companies?limit=1');
    }

    async getExistingCompanyData() {
        return this.$http.get(`/companies/view/${this.companyId}`);
    }

    async setData() {
        const path = [
            '/companies/edit/',
            this.company.id,
            '?user_id=',
            this.companyUserId
        ]
            .filter(Boolean)
            .join('');

        return this.$http
            .post(path, this.getDataToSave())
            .then(({ data }) => data)
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }
            });
    }

    getDataToSave() {
        return Object.entries(this.company).reduce(
            (
                acc: Record<
                    string,
                    string | number | boolean | MediaResource[]
                >,
                [key, value]
            ) => {
                if (this.canSaveProperty(key)) {
                    acc[key] = value;
                }

                return acc;
            },
            {}
        );
    }

    canSaveProperty(key: string) {
        if (this.isEditing) {
            return this.propsToSave.has(key);
        }

        if (this.isCreating) {
            return (
                this.propsToSave.has(key) && !this.propsToOmitForNew.has(key)
            );
        }

        return false;
    }

    getCountryNameById(id: number) {
        const country = this.countries.find(country => country.id === id);

        if (country) {
            return country.name;
        }

        return null;
    }

    setCountryByName(name: string) {
        const country = this.countries.find(country => country.name === name);

        if (country) {
            this.$set(this.company, 'country_id', country.id);
            this.countryChanged(country.id);
        }
    }

    async countryChanged(id: number) {
        await this.loadCountryISOs();

        this.$refs.phone.choose(this.countryISOs.countryIdsAndIsos[id]);
    }

    loadCountryISOs() {
        if (!Object.keys(this.countryISOs.isoAndCountryIds).length) {
            return this.$http
                .get('/companies/country_isos')
                .then(({ data }) => {
                    this.countryISOs = data.data;
                })
                .catch(error => {
                    if (!error.isIntercepted) {
                        this.$store.dispatch('notification/error', error);
                    }
                });
        }
    }

    async verifyAddress() {
        const address = this.prepareFullAddress();

        const result = await this.verify(address);

        if (result) {
            if (result.suggested) {
                this.suggestAddress(result);
            } else {
                this.setAddressVerified();
            }
        }
    }

    async verify(
        address: FullAddress
    ): Promise<AddressVerificationResult | null> {
        return this.$maps
            .geocode(address)
            .then(({ results }) => {
                return {
                    results,
                    suggested: this.$maps.suggest(address, results)
                };
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    if (error.code !== 'OK') {
                        this.warnNonVerifiedAddress();
                    }
                }

                return null;
            });
    }

    warnNonVerifiedAddress() {
        this.$store.dispatch('modal/open', 'cannot-verify-address');
    }

    confirmNonVerified(accept = false) {
        if (accept) {
            this.setAddressVerified();
        }

        this.$store.dispatch('modal/close', 'cannot-verify-address');
    }

    suggestAddress(result: AddressVerificationResult) {
        this.address = result;

        this.$store.dispatch('modal/open', 'suggested-address');
    }

    confirmSuggested(use = true) {
        if (use) {
            this.applySuggestedAddress();
        }

        this.setAddressVerified();

        this.$store.dispatch('modal/close', 'suggested-address');
    }

    applySuggestedAddress() {
        if (this.address) {
            Object.entries(this.address.suggested || {}).forEach(
                ([key, value]) => {
                    if (value) {
                        if (key === 'country') {
                            this.setCountryByName(value);
                        } else {
                            if (key === 'address') {
                                key = 'address1';
                            }
                            this.$set(this.company, key, value);
                        }
                    }
                }
            );

            this.address = null;
        }
    }

    cancel() {
        this.$emit('cancelled');
    }

    updateCompanyData(company: { id: number; user_id: number; name: string }) {
        if (company) {
            this.company.id = company.id;
            this.company.user_id = company.user_id;
            this.company.name = company.name;
        }
    }

    queueJob(handler: QueueItem) {
        this.batch.push(handler);
    }

    processQueue() {
        return Promise.all(this.batch.map(handler => handler()));
    }

    setErrors(errors?: Record<string, Record<string, string>>) {
        if (this.observer && errors) {
            for (const [field, errorsPerField] of Object.entries(errors)) {
                for (const msg of Object.values(errorsPerField)) {
                    this.observer.setErrors({
                        [field]: msg
                    });
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.dirty-address-indicator {
    background: $grey-light;
    border-radius: 0.5em;
    border: 1px dashed $grey-medium-light;
}
</style>
