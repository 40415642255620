<template>
    <v-row class="ma-0">
        <v-col
            v-for="(module, key) in feedUrls"
            :key="'rss-feed-' + key"
            class="pl-0 py-0"
            sm="12"
            md="6"
            cols="6"
        >
            <v-text-field
                ref="rssInput"
                :key="'rss-input-' + key"
                :value="module.link"
                :label="module.label"
                filled
                readonly
                dense
                @focus="$event.target.select()"
            >
                <template #prepend>
                    <v-icon>rss-square</v-icon>
                </template>
                <template #append>
                    <v-tooltip
                        bottom
                        :close-delay="copied ? 2000 : 500"
                        :nudge-right="copied ? 20 : 0"
                    >
                        <template #activator="{ on, attrs }">
                            <v-btn
                                v-clipboard:copy="module.link"
                                v-clipboard:success="onLinkCopied"
                                text
                                icon
                                class="pl-2 pb-2"
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>far fa-copy</v-icon>
                            </v-btn>
                        </template>
                        <span>
                            {{ copyTooltip }}
                        </span>
                    </v-tooltip>
                </template>
            </v-text-field>
        </v-col>
    </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { Company } from '@/types/Company';

const CompanyRssFeedsProps = Vue.extend({
    name: 'CompanyRssFeeds',
    props: {
        company: {
            type: Object as PropType<Company>,
            default() {
                return {
                    id: null
                };
            }
        }
    }
});

@Component
export default class CompanyRssFeeds extends CompanyRssFeedsProps {
    get feedUrls() {
        const baseUrl = 'https://ampifire.com/distribution_feeds/company';
        return [
            {
                label: 'News Article',
                link: `${baseUrl}/${this.company.id}/NewsArticles/rss`
            },
            {
                label: 'Blog Post',
                link: `${baseUrl}/${this.company.id}/BlogPosts/rss`
            },
            {
                label: 'Podcast',
                link: `${baseUrl}/${this.company.id}/Podcasts/rss`
            },
            {
                label: 'Slideshow',
                link: `${baseUrl}/${this.company.id}/Slideshows/rss`
            },
            {
                label: 'Infographic',
                link: `${baseUrl}/${this.company.id}/Infographics/rss`
            },
            {
                label: 'Video',
                link: `${baseUrl}/${this.company.id}/VideoPrs/rss`
            }
        ];
    }

    copied = false;

    get copyTooltip() {
        return this.copied ? 'Link Copied' : 'Click to Copy';
    }

    onLinkCopied() {
        this.copied = true;
        setTimeout(this.reset, 3000);
    }

    reset() {
        this.copied = false;
    }
}
</script>
