import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{staticClass:"ma-0"},_vm._l((_vm.feedUrls),function(module,key){return _c(VCol,{key:'rss-feed-' + key,staticClass:"pl-0 py-0",attrs:{"sm":"12","md":"6","cols":"6"}},[_c(VTextField,{key:'rss-input-' + key,ref:"rssInput",refInFor:true,attrs:{"value":module.link,"label":module.label,"filled":"","readonly":"","dense":""},on:{"focus":function($event){return $event.target.select()}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c(VIcon,[_vm._v("rss-square")])]},proxy:true},{key:"append",fn:function(){return [_c(VTooltip,{attrs:{"bottom":"","close-delay":_vm.copied ? 2000 : 500,"nudge-right":_vm.copied ? 20 : 0},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(module.link),expression:"module.link",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onLinkCopied),expression:"onLinkCopied",arg:"success"}],staticClass:"pl-2 pb-2",attrs:{"text":"","icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("far fa-copy")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.copyTooltip)+" ")])])]},proxy:true}],null,true)})],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }